<template>
  <div class="wrapper-life">
    <div class="life-inner">
      <ul>
        <li
          v-for="(item, ind) in menuData"
          :key="ind"
          @click="handleMenu(ind)"
          :class="ind === active ? 'active' : ''"
        >
          {{ item.label }}
        </li>
      </ul>
      <completeMachine v-if="active === 0"></completeMachine>
      <parts v-else></parts>
    </div>
  </div>
</template>

<script>
import completeMachine from './completeMachine.vue'
import parts from './parts.vue'
export default {
  components: { completeMachine, parts },
  data() {
    return {
      menuData: [
        {
          label: '电梯整机',
          value: 0
        },
        {
          label: '零部件',
          value: 1
        }
      ],
      active: 0
    }
  },
  methods: {
    handleMenu(ind) {
      this.active = ind
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper-life {
  width: 100%;
  height: 88%;
  padding: 0 46px;
  .life-inner {
    width: 100%;
    height: 88%;

    ul {
      height: 60px;
      display: flex;
      align-items: center;
      li {
        margin-left: 2.66%;
        font-size: 16px;
        color: #fff;
        padding: 5px 0;
        cursor: pointer;
      }
      li.active {
        border-bottom: 3px solid #1ed0f8;
        margin-left: 2.66%;
        font-size: 16px;
        color: #1ed0f8;
        padding: 5px 0;
      }
    }
  }
}
</style>
