<template>
  <div class="chart">
    <div
      class="line-chart"
      :id="chartId"
      style="height: 100%; width: 100%"
    ></div>
  </div>
</template>

<script>
import echarts from 'echarts'
export default {
  name: 'lineChart',
  /**
   * @params chartData 图表数据
   * @params keys 图表x轴
   * @params chartType 图表类型（line、bar）
   * @params chartId id
   * @params chartOption 个性化配置
   */
  props: {
    chartData: {
      style: Array,
      default: []
    },
    keys: {
      style: Array,
      default: []
    },
    chartType: {
      style: String,
      default: () => {
        return ''
      }
    },
    chartId: {
      style: String,
      default: ''
    },
    chartOption: {
      style: Object,
      default: {}
    },
    chartSeries: {
      style: Array,
      default: []
    }
  },
  data() {
    return {
      chart: null
    }
  },
  mounted() {
    this.initChart()
    window.addEventListener('resize', this.resize)
  },
  watch: {
    chartSeries: function (val, valOld) {
      this.updateChart()
    }
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
    window.removeEventListener('resize', this.resize)
  },
  methods: {
    updateChart: function () {
      const color = ['#00FF3D', '#00C6FD']
      const seriesData = this.chartSeries.map((item, i) => {
        let series = {
          name: item.chartId,
          data: item.chartData,
          type: item.chartType,
          smooth: true,
          symbolSize: 1,
          // stack: 'Total',
          lineStyle: {
            color: color[i],
            width: this.chartOption.lineWidth || 1
          },
          itemStyle: {
            normal: {
              color: (params) => {
                let color = ''
                if (params.seriesType === 'bar')
                  color =
                    this.chartOption.color[params.dataIndex % 2] ||
                    params.color + '99'
                else color = this.chartOption.color[0]
                return color
              }
            }
          },
          showSymbol: true, // 数据标点
          emphasis: {
            focus: 'series',
            label: {
              position: i === 0 ? 'left' : 'right',
              show: true,
              formatter: ({ value }) => {
                var arr = ['{value|' + value + '}']
                return arr.join('\n')
              },
              rich: {
                value: {
                  padding: [0, 16],
                  height: 24,
                  borderRadius: 3,
                  backgroundColor: '#FCAD14',
                  align: 'center',
                  color: '#FFFFFF',
                  fontSize: 14,
                  lineHeight: 24
                }
              }
            },
            itemStyle: {
              color: '#fff',
              borderColor: '#FCAD14',
              borderWidth: 5
            }
          }
        }
        return series
      })
      console.log('seriesData', this.seriesData, this.keys, seriesData)

      const options = {
        color: this.chartOption.color,
        barWidth: 20,
        grid: {
          top: 20,
          right: 20,
          bottom: 36,
          left: 20,
          containLabel: true
        },
        dataZoom: [
          {
            type: 'inside',
            start: 0,
            end: 100,
            zoomLock: true
          },
          {
            start: 0,
            end: 100,
            showDetail: false,
            fillerColor: 'rgba(30, 208, 248,0.3)',
            borderColor: 'rgba(30, 208, 248,1)',
            height: 20,
            bottom: 0,
            show: true
          }
        ],
        tooltip: {
          trigger: 'axis',
          show: this.chartOption.tooltip?.show || true,
          formatter: () => {
            return ''
          }
        },
        // legend: {
        //   color: '#000',
        //   data: [this.chartId]
        // },
        xAxis: [
          {
            type: 'category',
            data: this.keys[0],
            boundaryGap: true,
            axisTick: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: '#0DA6E1',
                textAlign: 'right'
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            splitArea: {
              show: false
            },
            min: function (value) {
              return Math.floor(value.min - 1)
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: '#0DA6E1',
                textAlign: 'right'
              }
            },
            axisTick: {
              show: false
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: '#0DA6E1'
              }
            }
          }
        ],
        series: seriesData
      }
      this.chart.setOption(options, true)
    },
    initChart() {
      const target = document.getElementById(this.chartId)
      this.chart = echarts.init(target)
      this.updateChart()
    },
    resize() {
      if (this.chart) this.chart.resize()
    }
  }
}
</script>
<style>
.chart {
  overflow: hidden;
}
</style>
