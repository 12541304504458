<template>
  <div>
    <div
      class="pie-chart"
      :id="chartId"
      :style="{ height: '100%', width: '100%' }"
    ></div>
  </div>
</template>

<script>
import echarts from 'echarts'
export default {
  /**
   * @param chartData {array} 进度条数据
   * @param top {number} 进度条长度
   * @param chartOption {object} 个性化配置参数
   * @param chartId {string} 图表id，也是图表title
   * @param unit {string} 单位
   *
   */
  props: {
    chartData: {
      style: Array,
      default: () => {
        return []
      }
    },
    top: {
      style: Number,
      default: () => {
        return 100
      }
    },
    chartOption: {
      stype: Object,
      default: () => {
        return {}
      }
    },
    chartId: {
      style: String,
      default: () => {
        return ''
      }
    },
    unit: {
      style: String,
      default: () => {
        return ''
      }
    }
  },
  data() {
    return {
      chart: null
    }
  },
  mounted() {
    this.initChart()
    window.addEventListener('resize', this.resize)
  },
  watch: {
    chartData: function () {
      this.updateChart()
    }
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
    window.removeEventListener('resize', this.resize)
  },
  methods: {
    updateChart: function () {
      const options = {
        color: this.chartOption.color,
        title: {
          text: this.chartId,
          textStyle: {
            width: '100%',
            height: 20,
            fontFamily: 'PingFangSC',
            color: this.chartOption.titleColor || '#1ED0F8',
            fontSize: this.chartOption.titleSize || 14
          },
          padding: 5,
          left: 'center',
          bottom: 0
        },
        series: [
          // 填充色
          {
            name: '',
            type: 'gauge',
            detail: false,
            clockwise:true,
            splitNumber: 1 , //刻度数量
            radius: '75%', //图表尺寸
            center: ['50%', '50%'],
            startAngle: 90, //开始刻度的角度
            endAngle: 90 - this.chartData[0] / this.top * 360, //结束刻度的角度
            axisLine: {
              show: false
            },
            axisTick: {
              show: true,
              lineStyle: {
                color: this.chartOption.color[0],
                width: 5
              },
              length: '15%',
              splitNumber: 40 * this.chartData[0] / this.top
            },
            splitLine: {
              show: false
            },
            axisLabel: {
              show: false
            }
          },
          // 背景色
          {
            name: '',
            type: 'gauge',
            detail: false,
            clockwise:true,
            // min:this.top,
            // max: 0,
            // min:0, max:this.top,
            splitNumber: 4, //刻度数量
            radius: '75%', //图表尺寸
            center: ['50%', '50%'],
            startAngle: 90, //开始刻度的角度
            endAngle: -270, //结束刻度的角度
            axisLine: {
              show: false
            },
            axisTick: {
              show: true,
              lineStyle: {
                color: this.chartOption.color[0],
                opacity: 0.4,
                width: 5
              },
              length: '15%',
              splitNumber: 10
            },
            splitLine: {
              show: false
            },
            axisLabel: {
              show: false
            }
          },
          // 中央数字
          {
            type: 'pie',
            data: this.chartData,
            radius: '80%',
            itemStyle: {
              color: 'transparent'
            },
            label: {
              show: true,
              position: 'center',
              formatter: this.chartData[0] + this.unit,
              color: this.chartOption.color,
              fontSize: 32,
              width: 59,
              height: 38,
              fontFamily: 'Roboto',
              fontWeight: 700
            }
          }
        ]
      }
      this.chart.setOption(options, true)
    },
    initChart() {
      const target = document.getElementById(this.chartId)
      this.chart = echarts.init(target)
      this.updateChart()
    },
    resize() {
      if (this.chart) this.chart.resize()
    }
  }
}
</script>
