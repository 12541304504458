<template>
  <div class="life-content">
    <div class="life-content-left">
      <dv-border-box-1 style="width: 100%; height: 48%">
        <border-box :src="srcTop">
          <span slot="title" class="title border-title"
            >总体可靠寿命预测直方图</span
          >
          <div slot="box-container">
            <lineChart
              chartId="总体可靠寿命预测直方图"
              :keys="keys"
              :chartData="sourceData"
              :chartOption="chartOption"
              chartType="line"
              style="width: 100%; height: 300px"
            />
          </div>
        </border-box>
      </dv-border-box-1>
      <dv-border-box-1 style="width: 100%; height: 48%">
        <border-box :src="srcTop">
          <span slot="title" class="title border-title">性能退化轨迹图</span>
          <div slot="box-container">
            <lineChart
              chartId="性能退化轨迹图"
              :keys="keys"
              :chartData="sourceData"
              :chartOption="chartOption"
              chartType="line"
              style="width: 100%; height: 300px"
            />
          </div>
        </border-box>
      </dv-border-box-1>
    </div>
    <div class="life-content-right">
      <dv-border-box-1 style="width: 100%; height: 100%">
        <border-box :src="srcTop">
          <span slot="title" class="title border-title">操作台</span>
          <div slot="box-container">
            <div class="container-form">
              <el-form ref="form" :model="form" label-width="80px">
                <el-col :span="9">
                  <el-form-item label="特征类型">
                    <el-select
                      v-model="seletedFeature"
                      placeholder="请选择"
                      :popper-append-to-body="false"
                      @change="handleChangeFeature"
                    >
                      <el-option
                        v-for="item in featureOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="9">
                  <el-form-item label="预测算法">
                    <el-select
                      v-model="selectedForecastAlg"
                      placeholder="请选择"
                      @change="filterIndex"
                      :popper-append-to-body="false"
                    >
                      <el-option
                        v-for="item in forecastAlgOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="5">
                  <div class="top-right" @click="startCompute">
                    <img
                      src="@/assets/img/calc.png"
                      width="100%"
                      height="100%"
                    />
                    <p>计算</p>
                  </div>
                </el-col>
              </el-form>
            </div>
            <moreLineChart
              chartId="操作台"
              :keys="operatingKeys"
              :chartData="sourceData"
              :chartOption="chartOption"
              :chartSeries="chartSeries"
              chartType="line"
              style="width: 100%; height: 230px"
            />
          </div>

          <div slot="box-container" class="box-container">
            <div class="container-left">
              <div class="container-left-title">
                <p>结果概述</p>
              </div>
              <div class="grid primary-box">
                <p>目标数据</p>
                <p>
                  {{
                    seletedFeature !== ''
                      ? featureOptions[seletedFeature].label
                      : '待选择'
                  }}
                </p>
                <p>预测算法</p>
                <p>
                  {{ a }}
                </p>
                <p>设备当前状态</p>
                <p>{{ currentResult }}</p>
                <p>未来一周状态</p>
                <p>{{ futureResult }}</p>
              </div>
            </div>
            <div class="container-right">
              <gaugeSpeedChart
                chartId="可靠度"
                :chartOption="chartOption1"
                style="width: 440px; height: 340px"
                :chartData="progressData"
              ></gaugeSpeedChart>
            </div>
          </div>
        </border-box>
      </dv-border-box-1>
    </div>
  </div>
</template>

<script>
import moreLineChart from '../../components/moreLineChart.vue'
import lineChart from '../../components/lineChart.vue'
import gaugeSpeedChart from '../../components/gaugeSpeedChart.vue'
import { getForecastResultData } from '@/api/forecast.js'
export default {
  components: { moreLineChart, gaugeSpeedChart, lineChart },

  data() {
    return {
      a: '',
      srcTop: require('../../../assets/img/line-chart.png'),
      sourceData: [],
      keys: [
        // '声强级(dB)',
        // '声强级(dB)1',
        // '声强级(dB)2',
        // '声强级(dB)3',
        // '声强级(dB)4',
        // '声强级(dB)5'
      ],
      chartOption: {
        height: '500px',
        color: ['rgba(0,198, 253,1)', 'rgba(0,198, 253,0)']
      },
      chartOption1: {
        color: ['#999']
      },
      progressData: [0],

      // 计算 下拉框
      featureOptions: [
        {
          value: 0,
          label: '声强级'
        },
        {
          value: 1,
          label: '加速度X'
        },
        {
          value: 2,
          label: '加速度Y'
        },
        {
          value: 3,
          label: '加速度Z'
        },
        {
          value: 4,
          label: '曳引机电流'
        },
        {
          value: 5,
          label: '门电机电流'
        }
      ],
      forecastAlgOptions: [
        {
          value: 0,
          label: 'BP神经网络'
        },
        {
          value: 1,
          label: '伽马过程'
        }
      ],
      seletedFeature: '',
      selectedForecastAlg: '',
      form: {},
      chartSeries: [],
      operatingKeys: [],
      //预测结果
      currentResult: '待计算',
      futureResult: '待计算'
    }
  },
  methods: {
    startCompute() {
      this.getforecastData()
    },
    filterIndex() {
      // forecastAlgOptions[selectedForecastAlg].label
      this.a =
        this.selectedForecastAlg !== ''
          ? this.forecastAlgOptions.filter(
              (el) => el.value === this.selectedForecastAlg
            )[0]?.label
          : '待选择'
      // return a
    },
    //获取预测结果
    getforecastData() {
      const currentDevice = this.$route.query.equipment
      getForecastResultData(
        currentDevice,
        this.seletedFeature,
        this.selectedForecastAlg
      ).then((res) => {
        this.chartSeries = [
          {
            chartId: '真实值',
            chartData: res.valueNew,
            chartType: 'line'
          },
          {
            chartId: '预测值',
            chartData: res.valueOld,
            chartType: 'line'
          }
        ]
        this.operatingKeys = [res.dateNew]
        // console.log(res.data);
        // that.lineDataX = res.data.dateNew
        // that.lineDataY = res.data.valueOld
        // that.lineForecast = res.data.valueNew
        // that.lineThreshold = res.data.threshold
        // // console.log(that.lineThreshold);
        let lengthOld = res.valueOld.length
        let r1 = res.valueOld[lengthOld - 1]
        this.currentResult = r1 < res.threshold ? '良好' : '警告'
        let r2 = res.valueNew[lengthOld]
        this.futureResult = r2 < res.threshold ? '良好' : '警告'
        this.progressData = res.result
      })
    },
    handleChangeFeature(val) {
      this.selectedForecastAlg = ''
      if (val == 1 || val == 2 || val == 3) {
        this.forecastAlgOptions = [
          {
            value: 1,
            label: '伽马过程'
          }
        ]
      } else {
        this.forecastAlgOptions = [
          {
            value: 0,
            label: 'BP神经网络'
          },
          {
            value: 1,
            label: '伽马过程'
          }
        ]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.life-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  .life-content-left {
    flex: 0 1 55%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .life-content-right {
    flex: 0 1 45%;
    height: 100%;
  }
  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 50px;
    margin-bottom: 34px;
  }
  .primary-box {
    width: 100%;
    padding: 0 38px;
    p {
      border: 1px solid #003aa3;
      line-height: 50px;
      text-align: center;
      color: #1ed0f8;
    }
  }
}

.box-container {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 100px;
  .container-left {
    width: 50%;
    height: auto;
    .container-left-title {
      width: 100%;
      margin-bottom: 20px;
      p {
        color: #63e2ff;
        line-height: 32px;
      }
    }
  }
  .container-right {
    width: 50%;
    height: auto;
  }
}

.container-form {
  width: 100%;
  height: 50px;
  margin-top: 20px;
}

.top-right {
  background-image: url('../../../assets/img/square-bg.png');
  width: 98px;
  height: 45px;
  padding: 16px;
  margin-right: 13px;
  float: right;
  font-size: 16px;
  color: #1ed0f8;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  img {
    width: 28px;
    height: 28px;
  }
  p {
    margin-left: 4px;
    line-height: 28px;
  }
}
</style>
