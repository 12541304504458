import request from '@/utils/request.js'

export function getThresholdRecordData(did) {
  return request({
    url: '/api/v1/devices/' + did + '/Dbforecast',
    method: 'get'
  })
}

// 电梯整机
export function getForecastResultData(did, type1, type2) {
  return request({
    // url: '/api/v1/devices/' + did + '/Dbforecast/0',
    url: '/api/v1/devices/' + did + '/Dbforecast/' + type1 + '/' + type2,
    method: 'get'
  })
}

// 电梯零部件
export function getForecastResultPartData(did, type1, type2) {
  return request({
    url: '/api/v1/devices/' + did + '/Dbforecast/part/' + type1 + '/' + type2,
    method: 'get'
  })
}
